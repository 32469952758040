var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Multiple Lists"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeMultiple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Drag and drop items of more than one list. Add same ")]), _c('code', [_vm._v("group")]), _c('span', [_vm._v(" to ")]), _c('code', [_vm._v("group")]), _c('span', [_vm._v(" prop.")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "text-primary font-weight-bold mb-2"
  }, [_vm._v(" People Group 1 ")]), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "list": _vm.list1,
      "tag": "ul",
      "group": "people"
    }
  }, _vm._l(_vm.list1, function (listItem, index) {
    return _c('b-list-group-item', {
      key: index,
      attrs: {
        "tag": "li"
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('b-avatar', {
      attrs: {
        "text": listItem.name.slice(0, 2)
      }
    }), _c('div', {
      staticClass: "ml-25"
    }, [_c('b-card-text', {
      staticClass: "mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(listItem.name) + " ")]), _c('small', [_vm._v(_vm._s(listItem.email))])], 1)], 1)]);
  }), 1)], 1), _c('b-col', {
    staticClass: "mt-sm-2 mt-md-0",
    attrs: {
      "md": "6"
    }
  }, [_c('h6', {
    staticClass: "text-primary font-weight-bold mb-2"
  }, [_vm._v(" People Group 2 ")]), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "list": _vm.list2,
      "tag": "ul",
      "group": "people"
    }
  }, _vm._l(_vm.list2, function (listItem, index) {
    return _c('b-list-group-item', {
      key: index,
      attrs: {
        "tag": "li"
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('b-avatar', {
      attrs: {
        "text": listItem.name.slice(0, 2)
      }
    }), _c('div', {
      staticClass: "ml-25"
    }, [_c('b-card-text', {
      staticClass: "mb-0 font-weight-bold"
    }, [_vm._v(" " + _vm._s(listItem.name) + " ")]), _c('small', [_vm._v(_vm._s(listItem.email))])], 1)], 1)]);
  }), 1)], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "6"
    }
  }, [_c('prism', {
    staticClass: "rounded",
    attrs: {
      "language": "javascript"
    }
  }, [_vm._v(" People Group 1: " + _vm._s(_vm.list1) + " ")])], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "md": "6"
    }
  }, [_c('prism', {
    staticClass: "rounded",
    attrs: {
      "language": "javascript"
    }
  }, [_vm._v(" People Group 2: " + _vm._s(_vm.list2) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }