var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Simple"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeSimple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Simple Draggable list with in sync to original list. Remove ")]), _c('code', [_vm._v("list")]), _c('span', [_vm._v(" prop to break synchronization with original list.")])]), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.list,
      callback: function callback($$v) {
        _vm.list = $$v;
      },
      expression: "list"
    }
  }, _vm._l(_vm.list, function (item, index) {
    return _c('b-list-group-item', {
      key: index,
      attrs: {
        "tag": "li"
      }
    }, [_c('strong', [_vm._v(" " + _vm._s(item))])]);
  }), 1), _c('prism', {
    staticClass: "rounded mt-2",
    attrs: {
      "language": "javascript"
    }
  }, [_vm._v("list: " + _vm._s(_vm.list) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }